import React, { createContext, useContext, ReactNode, useEffect, useState } from 'react'
import {
  AuthService,

  NavHint,
  type UrlParam,
  type UserTokenContext,
  type ErrorAndResultCallbackFn,
  type UserIdentityEvent,
  type UserSignInOptions,
  type UserSignOutPayload,
  type AuthorizationInfo,
  type SESSION_TYPE
} from '@vp/ubik-authfront' // web entry point

import { AuthContextType } from '../types'

const AuthContext = createContext<AuthContextType | null>(null)
type Config = {
  clientId?: string;
  oauthBaseURL?: string;
  connection?: string
  access_token_claims: any;
  tokenType: string;
  culture: string;
  returnTo: string;
}
interface AuthProviderProps {
  children: ReactNode;
  config: Config;
}

const AuthProvider = ({ children, config }: AuthProviderProps) => {
  const sessionType = config.access_token_claims['https://claims.cimpress.io/is_anonymous'] === true ? 'anon' : 'user'
  const authConfig = {
    clientId: config.clientId,
    oauthBaseURL: config.oauthBaseURL,
    culture: config.culture,
    returnTo: config.returnTo,
    connection: config.connection,
    tokenAdditionalInfo: {
      expiresIn: config.access_token_claims['exp'] as number,
      accessTokenClaims: config.access_token_claims,
      sessionType: sessionType as SESSION_TYPE,
      tokenType: config.tokenType,
    }
  }
  const authInstance = new AuthService(authConfig)
  const [userIdentity, setUserIdentity] = useState(authInstance.getIdentity())

  useEffect(() => {
    authInstance.onUserIdentityUpdate((newIdentity: UserIdentityEvent) => {
      if (!newIdentity) {
        return
      }
      setUserIdentity(newIdentity)
    })

    return () => {
      // Cleanup  if
    }
  }, [])

  return (
    <AuthContext.Provider value={{
      isSignedIn: authInstance.isSignedIn,
      getToken: authInstance.getToken,
      getTokenType: authInstance.getTokenType,
      getCanonicalId: authInstance.getCanonicalId,
      getProfile: authInstance.getProfile,
      getIdentity: () => userIdentity,
      signIn: authInstance.signIn,
      signOut: authInstance.signOut,
      getAuthorization: authInstance.getAuthorization,
      onUserIdentityUpdate: authInstance.onUserIdentityUpdate,
      getRecentAnonymousIds: authInstance.getRecentAnonymousIds,
      getAuthorizationHeader: authInstance.getAuthorizationHeader
    }}
    >
      {children}
    </AuthContext.Provider>
  )
}

// Custom hook to use the context
const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export {
  AuthProvider,
  useAuth,

  NavHint,
  type UrlParam,
  type Config,
  type UserSignInOptions,
  type UserSignOutPayload,
  type SESSION_TYPE,
  type UserTokenContext,
  type UserIdentityEvent,
  type AuthorizationInfo,
  type ErrorAndResultCallbackFn,
}
